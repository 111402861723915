.home-page {

    .text-zone {
        position: absolute;
        left: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
        text-align: center;
    }
    
    h1 {
        color: white;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        animation: fadeIn 1s .5s backwards;
    }

    h2 {
        color: whitesmoke;
        margin-top: 20px;
        margin-bottom: 40px;
        font-weight: 2000;
        font-size: 17px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1s backwards;
    }

    .flat-button {
        color: #383838;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 2px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 20px;
        border: 1px solid #383838;
        border-radius: 25px;
        background-color: #abc9d5;
        margin-top: 50px;
        animation: fadeIn 1s 1.5s backwards;
        white-space: nowrap;

        &:hover {
            background: #9db8c3;
            color: #333
        }
    }

    // .profile-container {
    //     display:block;
    //     top: 0%;
    //     margin-left:auto;
    //     margin-right: auto;
    //     margin-top: 3%;
    //     height: 35%;
    //     border-radius: 50%;
    //     // border: 2px solid black;
    // }
}