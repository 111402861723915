@import 'animate.css';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  height: 100%;
  min-height: 566px;
  position: absolute;
  // opacity: 0;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  // animation: fadeIn 1s forwards;
  // animation-delay: 1s;
}

.about-page ,
.contact-page,
.project {
  .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 75%;

      h1 {
          font-size: 53px;
          font-family: 'Coolvetica';
          color: black;
          font-weight: 400;
          margin-top: 0;
          position: relative;
          margin-bottom: 40px;
          left: 10px;
      }

      p {
          font-style: 13px;
          color: gray;
          font-family: sans-serif;
          font-weight: 300;
          min-width: fit-content;
          animation: fadeIn .5s 1s backwards;
      }
  }
}

input,
textarea {
  font-family: 'Helvetica Neue';
}