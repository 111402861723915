.contact-form {
    width: 100%;
    margin-top: -10px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp .5s 1s;
            animation-fill-mode: forwards;
            clear: both;
            border-radius: 8px;
        }

        li.half{
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: white;
        height: 50px;
        font-size: 16px;
        color: black;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        border: 0;
        background: white;
        height: 50px;
        font-size: 16px;
        color: black;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: black;
        background: 0 0;
        font: 11px;
        font-weight: 500;
        border: 1px solid black;
        letter-spacing: 3px;
        text-decoration: none;
        background-color: #abc9d5;
        padding: 8px 10px;
        float: right;
        border-radius: 8px;

        &:hover {
            background: #9db8c3;
            color: black
        }
    }
}

.pics {
    position: relative;
    top: -20px;
    opacity: 0;
    animation: fadeIn .5s .5s;
    animation-fill-mode: forwards;
    
    b {
        font-size: 15px;
        position: relative;
        top: -5px;
    }
}


.info-map {
    position: absolute;
    background-color: #000;
    top: 50px;
    right: 30%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn .5s 1.2s;
    animation-fill-mode: forwards;

    span {
        font-size: 16px;
        display: block;
        padding-top: 20px;
        color: #ffd700; 
    }
}

.text-zone:nth-child(1) {
    left: 8%;
}

.map-wrap {
    // background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 100%;
}

.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s .5s;
    animation-fill-mode: forwards;
}