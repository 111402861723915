.project {
    padding: 4rem;
    background: #dedcde;
    height: calc(100%);
}

h1 {
  text-align: center;
  font-size: 45px;
  font-family: 'Coolvetica';
  color: black;
  // opacity: 0;
  // animation: fadeIn 05s 1s backwards;
  // animation-fill-mode: forwards;
}

.project_container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.project_wrapper {
  position: relative;
  margin: 10px 30px 45px;
}

.project_items {
  margin-bottom: 24px;
  gap: 100px;
  padding: 0px;
  opacity: 0;
  animation: fadeIn 1s .5s backwards;
  animation-fill-mode: forwards;
}

.card_item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.card_item_link {
  display: flex;
  flex-flow: column;
  width: 100%;
  // box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  // -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  // filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.card_item_pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow:hidden;
  margin-left: 0;
  margin-top: 0;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 1s;
}

.card_item_pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 0px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.card_item_img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit:fill;
  transition: all 0.2s linear;
  border-radius: 15px;
}

.card_item_info {
  padding: 0px 30px 0px;
  background: white;
  border-radius: 15px;
  text-align:center;
}

.card_item_text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .project_items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .project_item {
    margin-bottom: 2rem;
  }
}