.personal-container {
    position: absolute;
    width: 50%;
    left: 50%;
    height: 90%;
}
.personal-interests {
    position: relative;
    height: 50%;
    top: 45%;
    width: 100%;
    margin-top: 8rem;
}
.personal-pictures {
    position: absolute;
    width: 600px;
    height: 370px;
    margin: 0 auto;
    opacity: 0;
    animation: fadeIn 1s .5s;
    animation-fill-mode: forwards;
}
.text-zone {
    top: 20%;
}

h1 {
    letter-spacing: 3px;
    // animation: fadeIn 0.3s .8s backwards;
    // animation-fill-mode: forwards;
}

h2 {
    font-size: 30px;

    animation: fadeIn .5s .5s backwards;
    // margin-top: 0px;
    // margin-bottom: 0px;
}

h3 {
    font-size: 20px;
    margin-bottom: 0px;
}

p {
    font-size: 16px;
    color: #313131;
}

.skillbar {
    position: relative;
    left: -5%;
    list-style: none;
    animation: fadeIn .5s 1s backwards;
    padding-bottom: 2rem;
}

.skillbar li {
    // padding: 5px;
    height: 0.6rem;
    width: 100%;
    position: relative;
    margin-top: 4rem;
    margin-bottom: 4rem;

    background: black;
    padding: 0;
    line-height: 18px;
}

.passions {
    list-style: none;
    position: absolute;
    left: -3%;
    animation: fadeIn .5s 1s backwards;
    top: 5rem;
    margin-top: 0px;
}
.passioninfo {
    position: relative;
    font-size: 15px;
    font-family: sans-serif;
}

.icon {
    position: absolute;
    top: -3rem;
}


.progress {
    position: relative;
    height: 100%;
    background: #a1a1a1;

    span {
        position: absolute;
        right: 0;
        top: -3rem;
        display: block;
        color: white;
        font-size: 1.1rem;
        line-height: 1;
        background: #313131;
        padding: 0.6rem 0.6rem;
        border: 1px solid #313131;
        border-radius: 3px;
    }
    ::after {
        position: absolute;
        left: 50%;
        bottom: -5px;
        margin-left: -5px;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 5px solid #313131;
        content: "";
    }
}

.percent90 {
    width: 90%;
}
.percent80 {
    width: 80%;
}
.percent75 {
    width: 75%;
}
.percent65 {
    width: 65%;
}